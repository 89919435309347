import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { LocalModel } from '../../models/local.model';
import { DatePipe } from '@angular/common';
import { ExporterService } from '../../services/exporter/exporter.service';
import { AlertService } from '../../services/alert/alert.service';
import { StorageService } from '../../services/storage/storage.service';
import { ComunidadModel } from '../../model/comunidad.model';
import { UserModel } from '../../model/vecino.model';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { ComunityExtrasModel } from '../../model/comunity-extras.model';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {
  @Select((state) => state.core.users) users$: Observable<any>;
  @Select((state) => state.core.selectedComunity) selectedComunity$: Observable<any>;
  @Select((state) => state.core.extras) extras$: Observable<any>;

  disable = false;
  selectedComunidad: ComunidadModel;
  users: UserModel[];
  selectedFechaOption = 'todo';
  date = new Date();

  localDataCached: LocalModel[] = [];
  selectedItem: any;
  selectedComunity: ComunidadModel;
  selectedTab = 'local';
  extras: ComunityExtrasModel;

  constructor(
    private datePipe: DatePipe,
    public httpService: HttpService,
    public alertService: AlertService,
    public exporterService: ExporterService,
    public storageService: StorageService,
    private store: Store
  ) {}

  ngOnInit() {
    this.init();
  }

  init() {
    this.selectedComunity$.subscribe((selectedComunity) => {
      if (selectedComunity) {
        this.selectedComunity = selectedComunity;
      }
    });
    this.users$.subscribe((users) => {
      if (users && users.length > 0) {
        this.users = users;
      }
    });
    this.extras$.subscribe((extras) => {
      if (extras) {
        this.extras = extras;
        this.switchActiveTab();
      }
    });
  }

  isTabActive(label: string): boolean {
    return this.selectedTab === label;
  }
  selectTabPage(label: string) {
    this.selectedTab = label;
  }

  formatDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
  }

  switchActiveTab() {
    const comExtras = this.selectedComunity ? this.selectedComunity.extras : null;
    if (this.selectedComunity && comExtras && this.selectedComunity.extras.localActive) {
      this.selectTabPage('local');
    } else if (comExtras.padelActive) {
      this.selectTabPage('padel');
    } else if (comExtras.piscinaActive) {
      this.selectTabPage('piscina');
    } else if (comExtras.solariumActive) {
      this.selectTabPage('solarium');
    } else if (comExtras.tenisActive) {
      this.selectTabPage('tenis');
    }
  }
}
