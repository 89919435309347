import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AdminUserModel } from '../../../model/admin-user.model';

@Component({
  selector: 'app-permissions-settings',
  templateUrl: './permissions-settings.component.html',
  styleUrls: ['./permissions-settings.component.scss']
})
export class PermissionsSettingsComponent implements OnInit {
  @Select((state) => state.core.adminUser) adminUser$: Observable<any>;
  adminUser: AdminUserModel;

  constructor() {}

  ngOnInit() {
    this.adminUser$.subscribe((adminUser) => {
      if (adminUser) {
        this.adminUser = adminUser;
      }
    });
  }
}
