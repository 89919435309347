import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NoticiaModel } from '../../../model/noticia.model';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent implements OnInit {
  @Input() data: any;
  @Output() done = new EventEmitter<Boolean>(false);
  closing = false;
  constructor() {}

  ngOnInit() {}
  cancel() {
    this.done.emit(true);
  }
  close() {
    this.closingAnimation();
    setTimeout(() => {
      this.done.emit(true);
    }, 400);
  }

  closingAnimation() {
    this.closing = true;
  }
}
