import { Action, State, StateContext, Store } from '@ngxs/store';
import { throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { InviteUser } from '../../../models/invite-user.model';
import { AlertService } from '../../../services/alert/alert.service';
import { HttpService } from '../../../services/http/http.service';
import { GetInviteUsers, SendInvitesUsers } from './gestion.actions';
export class GestionStateModel {
  invitesUsers: InviteUser[];
}
export const GestionStateDefaults: GestionStateModel = {
  invitesUsers: null
};

@State<GestionStateModel>({
  name: 'gestion',
  defaults: GestionStateDefaults
})
export class GestionState {
  constructor(private http: HttpService, private store: Store, private alertService: AlertService) {}
  @Action(GetInviteUsers)
  getInvitesUsers(ctx: StateContext<GestionStateModel>, { code }) {
    return this.http.getInviteUsersList(code).pipe(
      tap((result: any) => {
        ctx.patchState({
          invitesUsers: result ? result : null
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(SendInvitesUsers)
  sendInvitesUsers(ctx: StateContext<GestionStateModel>, { dto }) {
    return this.http.sendInvitesUsers(dto).pipe(
      tap((result: any) => {
        this.alertService.popNotification('success', 'info', 'Invitaciones enviadas correctamente');
        this.store.dispatch(new GetInviteUsers(dto.code));
      }),
      catchError((err: any) => {
        this.alertService.popNotification('error', 'info', 'Hubo un error enviando las invitaciones, por favor pruebe más tarde');
        return throwError(err);
      })
    );
  }
}
