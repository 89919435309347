import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  @Input() data: any;
  @Output() done = new EventEmitter<Boolean>(false);
  closing = false;
  constructor() {}

  ngOnInit() {}
  cancel() {
    this.done.emit(true);
  }
  close() {
    this.closingAnimation();
    setTimeout(() => {
      this.done.emit(true);
    }, 400);
  }

  closingAnimation() {
    this.closing = true;
  }
}
