import { ComunityExtrasModel } from './comunity-extras.model';

export class ComunidadModel {
  id?: number;
  type: string;
  type_comunity: string;
  code: string;
  name: string;
  admin_name: string;
  admin_email: string;
  admin_phone: string;
  admin_adress: string;
  com_image: string;
  created_at: Date;
  auth_token: string;
  forfait: string;
  extras: ComunityExtrasModel;
}
