import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentModel } from '../../../model/document.model';

@Component({
  selector: 'app-documento',
  templateUrl: './documento.component.html',
  styleUrls: ['./documento.component.scss']
})
export class DocumentoComponent implements OnInit {
  @Input() data: DocumentModel;
  @Output() done = new EventEmitter<Boolean>(false);
  @Output() disable = new EventEmitter<any>(null);
  closing = false;
  constructor() {}

  ngOnInit() {}

  disableNoticia() {
    this.disable.emit(this.data);
    this.close();
  }
  close() {
    this.closingAnimation();
    setTimeout(() => {
      this.done.emit(true);
    }, 400);
  }

  closingAnimation() {
    this.closing = true;
  }
}
