import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Logout } from '../../views/home/store/home.actions';

const AUTO_REDIRECT_TIMEOUT = 15 * 60 * 1000; // 15sec
@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss']
})
export class SessionExpiredComponent implements OnInit {
  sessionExpiredIcon = 'assets/new-images/session-expired.png';
  constructor(private store: Store) {}

  ngOnInit() {}

  autoRedirectLogin() {
    setTimeout(() => {
      this.redirectLogin();
    }, AUTO_REDIRECT_TIMEOUT);
  }
  redirectLogin() {
    this.store.dispatch(new Logout());
  }
}
