import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DismissImageViewer } from '../../views/home/store/home.actions';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {
  @Select((state) => state.core.imageViewerUrl) imageViewerUrl$: Observable<any>;
  url = '';
  closeIcon = 'assets/img/close-white.png';
  downloadIcon = 'assets/img/download-white.png';
  imageLoaded = false;
  constructor(private store: Store) {}

  ngOnInit() {
    this.imageViewerUrl$.subscribe((imageViewerUrl) => {
      if (imageViewerUrl) {
        this.url = imageViewerUrl;
        this.imageLoaded = true;
      }
    });
  }

  handleMissingImage(event) {
    this.imageLoaded = false;
  }

  close() {
    this.store.dispatch(new DismissImageViewer());
  }
}
