import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import * as mime from 'mime-types';
import * as moment from 'moment';
import { ComunityModel } from '../../models/comunity.model';
import { NoticiaModel } from '../../model/noticia.model';
import { DisableNoticia, GetNoticias, PopNewNoticia } from '../home/store/home.actions';
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.scss']
})
export class NoticiasComponent implements OnInit {
  @Select((state) => state.core.noticias) noticias$: Observable<any>;
  refreshIcon = 'assets/img/refresh.png';
  addIcon = 'assets/img/plus-white.png';
  list = true;
  nueva = false;
  model: Model = {
    title: '',
    description: '',
    file: null,
    ext: ''
  };
  description: string;
  complexForm: FormGroup;
  addFileURL = 'https://backbone.berekstan.com:4438/upload';
  searchValue = '';

  selectedComunidad: ComunityModel;
  noticias = [];
  filteredNoticias = [];
  selectedNoticia: NoticiaModel;

  constructor(private fb: FormBuilder, private store: Store, private modal: NzModalService) {
    this.complexForm = this.fb.group({
      titulo: [null, Validators.required],
      description: [null, Validators.required]
    });
  }

  ngOnInit() {
    this.noticias$.subscribe((noticias) => {
      if (noticias && noticias.length > 0) {
        this.filteredNoticias = Object.assign([]);
        this.noticias = Object.assign([]);
        this.noticias = noticias;
        this.filteredNoticias = noticias;
      } else {
        this.filteredNoticias = Object.assign([]);
        this.noticias = Object.assign([]);
      }
    });
  }
  resetSearch(): void {
    this.searchValue = '';
    this.search('');
  }

  search(value: string): void {
    this.searchValue = value;
    setTimeout(() => {
      this.filteredNoticias = this.noticias.filter((item: NoticiaModel) =>
        item.title
          .trim()
          .toLowerCase()
          .includes(this.searchValue.trim().toLowerCase())
      );
    }, 500);
  }
  openNewNoticia() {
    this.store.dispatch(new PopNewNoticia(true));
  }
  exitNoticia(done) {
    this.selectedNoticia = null;
  }
  selectNoticia(incidencia) {
    this.selectedNoticia = incidencia;
  }
  refresh() {
    this.getNoticias();
  }

  getNoticias() {
    this.store.dispatch(new GetNoticias());
  }

  submitForm(value: any): void {
    console.log(value);
  }

  getExtension(type) {
    return mime.extension(type);
  }
  getTimeStamp() {
    return moment().format('YYYY_MM_DD_HH_mm_ss');
  }

  setList() {
    this.getNoticias();
    this.list = true;
    this.nueva = false;
  }

  setNuevaNoticia() {
    this.list = false;
    this.nueva = true;
  }

  onDisableNoticia(noticia) {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: '¿ Está seguro que quiere desactivar esta noticia ?<br/>Ya no aparecerá en la app de la comunidad',
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.store.dispatch(new DisableNoticia(noticia.id));
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
    this.selectedNoticia = null;
  }
  disableNoticia(noticia) {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: '¿ Está seguro que quiere desactivar esta noticia ?<br/>Ya no aparecerá en la app de la comunidad',
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.store.dispatch(new DisableNoticia(noticia.id));
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
    this.selectedNoticia = null;
  }
}

interface Model {
  title: string;
  description: string;
  file: any;
  ext: string;
}
