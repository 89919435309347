import { Component, OnInit } from '@angular/core';
import { IncidenciaModel } from '../../../model/incidencia.model';
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { SelectTraspasoIncidencia } from '../../home/store/home.actions';
import { NzModalService } from 'ng-zorro-antd';
import { ServiceModel } from '../../../models/service.model';
import { ComunidadModel } from '../../../model/comunidad.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-traspaso-incidencia',
  templateUrl: './traspaso-incidencia.component.html',
  styleUrls: ['./traspaso-incidencia.component.scss']
})
export class TraspasoIncidenciaComponent implements OnInit {
  @Select((state) => state.core.selectedTraspasoIncidencia)
  selectedTraspasoIncidencia$: Observable<any>;
  @Select((state) => state.core.selectedComunity) selectedComunity$: Observable<any>;
  @Select((state) => state.core.services) services$: Observable<any>;
  data: IncidenciaModel;
  services: ServiceModel[] = [];
  selectedGremio: ServiceModel;
  inputGremio;
  selectGremio = true;
  selectedComunity: ComunidadModel;
  closing = false;
  constructor(private store: Store, private modal: NzModalService, private datepipe: DatePipe) {}

  ngOnInit() {
    this.selectedTraspasoIncidencia$.subscribe((selectedTraspasoIncidencia) => {
      if (selectedTraspasoIncidencia) {
        this.data = {
          ...selectedTraspasoIncidencia
        };
      }
    });
    this.services$.subscribe((services) => {
      if (services && services.length > 0) {
        this.services = services;
      }
    });
    this.selectedComunity$.subscribe((selectedComunity) => {
      if (selectedComunity) {
        this.selectedComunity = selectedComunity;
      }
    });
  }
  changeSelectGremio(event) {
    this.selectGremio = event.checked;
    this.inputGremio = '';
    this.selectedGremio = null;
  }
  onGremioSelected(service) {
    this.selectedGremio = service;
  }
  onChange(type: string) {
    switch (type) {
      case 'pendiente':
        this.data.state = 1;
        break;
      case 'procesando':
        this.data.state = 3;
        break;
      case 'solucionado':
        this.data.state = 2;
        break;
    }
  }
  triggerOpenEmail() {
    if (this.selectedGremio) {
      const subject = `Traspaso Incidencia comunidad: ${this.selectedComunity.name}`;
      const body = `
      DATOS DE LA INCIDENCIA:\n\n
      Comunidad:  ${this.selectedComunity.name}\n
      Fecha alta:  ${this.formatDate(this.data.created_at)}\n
      Categoría:  ${this.data.category}\n
      Tipo:  ${this.data.type}\n
      Descripción:  ${this.data.description}\n\n\n
      Traspasado realizado desde: ICOMUNITY - GESTOR DIGITAL DE FINCAS`;
      try {
        const mailText = `mailto:${this.selectedGremio.email}?subject=${subject}&body=${(window as any).encodeURIComponent(body)}`;
        window.location.href = mailText;
      } catch (error) {
        console.log('open mail client error ', error);
      }
    } else if (this.inputGremio) {
      const subject = `Traspaso Incidencia comunidad: ${this.selectedComunity.name}`;
      const body = `
      DATOS DE LA INCIDENCIA:\n\n
      Comunidad:  ${this.selectedComunity.name}\n
      Fecha alta:  ${this.formatDate(this.data.created_at)}\n
      Categoría:  ${this.data.category}\n
      Tipo:  ${this.data.type}\n
      Descripción:  ${this.data.description}\n\n\n
      Traspasado realizado desde: ICOMUNITY - GESTOR DIGITAL DE COMUNIDADES DE PROPIETARIOS`;
      try {
        const mailText = `mailto:${this.inputGremio}?subject=${subject}&body=${(window as any).encodeURIComponent(body)}`;
        window.location.href = mailText;
      } catch (error) {
        console.log('open mail client error ', error);
      }
    } else {
      this.modal.info({
        nzTitle: 'ATENCIÓN',
        nzContent: 'No ha seleccionado ningún gremio',
        nzOkText: 'Ok',
        nzOkType: 'primary'
      });
    }
  }
  close() {
    this.closingAnimation();
    setTimeout(() => {
      this.store.dispatch(new SelectTraspasoIncidencia(null));
    }, 400);
  }

  closingAnimation() {
    this.closing = true;
  }
  formatDate(date) {
    return this.datepipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
  }
}
