export const PREFIX_STORAGE = 'prop-';
export const KEY_COMUNIDADES = 'comunidades';
export const KEY_INCIDENCIAS = 'incidencias';
export const KEY_NOTICIAS = 'noticias';
export const KEY_LOCAL_BOOKING = 'local-booking';
export const KEY_PADEL_BOOKING = 'padel-booking';
export const KEY_PISCINA_BOOKING = 'piscina-booking';
export const KEY_SOLARIUM_BOOKING = 'solarium-booking';
export const KEY_SETTINGS = 'settings';
export const KEY_USERS = 'users';
export const KEY_COM_PHONES = 'com-phones';
export const KEY_COM_SERVICES = 'com-services';
export const KEY_SELECTED_COMUNITY = 'selected-com';
export const KEY_ADMIN_INFOS = 'admin-infos';
export const KEY_AUTH_TOKEN = 'auth-token';
export const KEY_FORFAIT = 'forfait';
