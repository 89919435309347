import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { Router } from '@angular/router';
import { KEY_AUTH_TOKEN } from '../service.constants';

@Injectable()
export class SessionService {
  constructor(
    private storageService: StorageService,
    private router: Router
  ) { }


  saveAuthToken(token: string) {
    this.storageService.saveData(KEY_AUTH_TOKEN, token);
  }
  fetchAutToken() {
    return this.storageService.getData(KEY_AUTH_TOKEN);
  }
  isLoggedIn() {
    return this.storageService.getData('session');
  }
  login() {
    this.storageService.saveData('session', true);
    this.router.navigate(['home']);
  }
  logout() {
    this.storageService.clearAll();
    this.router.navigate(['login']);
  }

  clearAll() {
    this.storageService.clearAll();
  }
}
