export const FILTRO_FECHA_ITEMS = [
  { label: 'Todos', value: 'todo' },
  { label: 'Este mes', value: 'this' },
  { label: 'El mes anterior', value: 'prev' },
  { label: 'Este año', value: 'year' },
  { label: 'Buscar con intervales', value: 'interval' }
];

export const SET_DATE_PNPUT = {
  in: {
    bool: false,
    value: new Date()
  },
  fin: {
    bool: false,
    value: new Date()
  }
};
