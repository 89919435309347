import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { NzModalService } from 'ng-zorro-antd';
import { Observable } from 'rxjs';
import { SendInviteUserDto } from '../../../common/dtos/send-invite-user.dto';
import { ComunidadModel } from '../../../model/comunidad.model';
import { InviteUser } from '../../../models/invite-user.model';
import { UtilsService } from '../../../services/utils.service.ts';
import { GetInviteUsers, SendInvitesUsers } from '../store/gestion.actions';

@Component({
  selector: 'app-invite-users',
  templateUrl: './invite-users.component.html',
  styleUrls: ['./invite-users.component.scss']
})
export class InviteUsersComponent implements OnInit {
  @Input() comunity: ComunidadModel;
  @Output() done = new EventEmitter<Boolean>(false);
  @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;
  @Select((state) => state.gestion.invitesUsers) invitesUsers$: Observable<any>;
  invites: InviteUser[] = [];
  tags = [];
  closing = false;
  inputVisible = false;
  inputValue = '';
  constructor(private store: Store, private utilsService: UtilsService, private modal: NzModalService) {}

  ngOnInit() {
    this.invitesUsers$.subscribe((invites) => {
      if (invites) {
        this.invites = invites;
      }
    });
    this.loadInvites();
  }

  resetTags() {
    this.tags = [];
    this.inputVisible = false;
    this.inputValue = '';
  }
  sendInvites() {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: '¿ Desea enviar las invitaciones a estos usuarios ?',
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        const dto: SendInviteUserDto = {
          code: this.comunity.code,
          comunity: this.comunity.name,
          emails: this.tags
        };
        this.store.dispatch(new SendInvitesUsers(dto));
        this.resetTags();
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }
  loadInvites() {
    this.store.dispatch(new GetInviteUsers(this.comunity.code));
  }
  handleClose(removedTag: {}): void {
    this.tags = this.tags.filter((tag) => tag !== removedTag);
  }

  sliceTagName(tag: string): string {
    const isLongTag = tag.length > 20;
    return isLongTag ? `${tag.slice(0, 20)}...` : tag;
  }

  showInput(): void {
    this.inputVisible = true;
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    }, 10);
  }

  handleInputConfirm(): void {
    if (this.inputValue && this.utilsService.validateEmail(this.inputValue) && this.tags.indexOf(this.inputValue) === -1) {
      this.tags = [...this.tags, this.inputValue];
    }
    this.inputValue = '';
    this.inputVisible = false;
  }

  close() {
    this.closingAnimation();
    setTimeout(() => {
      this.done.emit(true);
    }, 400);
  }

  closingAnimation() {
    this.closing = true;
  }
}
