import { ComunidadModel } from '../model/comunidad.model';

export class LocalModel {
  id: string;
  code: string;
  name: string;
  email: string;
  adress: string;
  day: string;
  hours: string;
  price: string;
  active: boolean;
  expired: boolean;
  countHours?: string;
  createdAt: Date;
  comunidad?: ComunidadModel;
}
