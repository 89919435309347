import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../../services/modal/modal.service';
import { ServiceModel } from '../../models/service.model';

@Component({
  selector: 'app-service-modal',
  templateUrl: './service-modal.component.html',
  styleUrls: ['./service-modal.component.scss']
})
export class ServiceModalComponent implements OnInit {

  @Input() serviceItem : ServiceModel;
  @Output() serviceSaved = new EventEmitter<ServiceModel>();
  okIcon = 'assets/icon/ok.png';
  closeIcon = 'assets/icon/close.png';

  constructor(public modalService:ModalService) { }

  ngOnInit() {

  }

  saveChanges(){
    this.modalService.hideServiceModal();
    this.serviceSaved.emit(this.serviceItem);
    
  }
  toggleChange(event){
    let state = event.checked;
    this.serviceItem.active = state ? 1 : 0;
  }

}
