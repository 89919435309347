import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-incidencia',
  templateUrl: './new-incidencia.component.html',
  styleUrls: ['./new-incidencia.component.scss']
})
export class NewIncidenciaComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
