import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { NoticiasComponent } from './views/noticias/noticias.component';
import { LogoutComponent } from './components/logout/logout.component';
import { HomeComponent } from './views/home/home.component';
import { GestionComponent } from './views/gestion/gestion.component';
import { DialogLogoutComponent } from './components/dialog-logout/dialog-logout.component';
import { BookingComponent } from './views/booking/booking.component';

import { HttpService } from './services/http/http.service';
import { AuthService } from './services/auth/auth.service';
import { AlertService } from './services/alert/alert.service';
import { LoginComponent } from './views/login/login.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NgProgressModule, NgProgressBrowserXhr } from 'ngx-progressbar';
import { IncidenciasComponent } from './views/incidencias/incidencias.component';
import { ComunidadComponent } from './views/comunidad/comunidad.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';

import {
  MatNativeDateModule,
  MatToolbarModule,
  MatCardModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatTabsModule,
  MatTableModule,
  MatSelectModule,
  MatDialogModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatSlideToggleModule
} from '@angular/material';
import { ServiceModalComponent } from './components/service-modal/service-modal.component';
import { PhoneModalComponent } from './components/phone-modal/phone-modal.component';
import { ExporterService } from './services/exporter/exporter.service';
import { ValidationModalComponent } from './components/validation-modal/validation-modal.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserXhr } from '@angular/http';
import { SessionService } from './services/session/session.service';
import { ModalService } from './services/modal/modal.service';
import { StorageService } from './services/storage/storage.service';
import { AuthGuard } from './common/guards/auth.guard';
import { LoadingSpinnerInterceptor } from './common/interceptor/loading-spinner.interceptor';
import { AuthInterceptor } from './common/interceptor/auth.interceptor';
import { HttpErrorInterceptor } from './common/interceptor/error.interceptor';
import { NoDataComponent } from './components/no-data/no-data.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ContactSupportComponent } from './components/contact-support/contact-support.component';
import { IncidenciaComponent } from './views/incidencias/incidencia/incidencia.component';
import { NoticiaComponent } from './views/noticias/noticia/noticia.component';
import { BookComponent } from './views/booking/book/book.component';
import { AddPhoneComponent } from './views/gestion/add-phone/add-phone.component';
import { AddServiceComponent } from './views/gestion/add-service/add-service.component';
import { EditPhoneComponent } from './views/gestion/edit-phone/edit-phone.component';
import { EditServiceComponent } from './views/gestion/edit-service/edit-service.component';
import { UserDetailComponent } from './views/gestion/user-detail/user-detail.component';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { NewIncidenciaComponent } from './views/incidencias/new-incidencia/new-incidencia.component';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { CoreState } from './views/home/store/home.state';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NewNoticiaComponent } from './views/noticias/new-noticia/new-noticia.component';
// tslint:disable-next-line: max-line-length
import {
  es_ES,
  NzCheckboxModule,
  NzDatePickerComponent,
  NzDatePickerModule,
  NzDividerModule,
  NzFormModule,
  NzIconModule,
  NzLayoutModule,
  NzModalModule,
  NzRadioModule,
  NzSelectModule,
  NzSwitchModule,
  NzTableModule,
  NzTagModule,
  NzToolTipModule,
  NzUploadModule,
  NZ_I18N
} from 'ng-zorro-antd';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { TraspasoIncidenciaComponent } from './views/incidencias/traspaso-incidencia/traspaso-incidencia.component';
import { AddDocumentComponent } from './views/documents/add-document/add-document.component';
import { AdminComponent } from './views/admin/admin.component';
import { AdminAuthGuard } from './common/guards/admin-guard';
import { TicketsComponent } from './views/admin/tickets/tickets.component';
import { ManageComponent } from './views/admin/manage/manage.component';
import { SettingsWebComponent } from './views/admin/settings-web/settings-web.component';
import { SettingsMobileComponent } from './views/admin/settings-mobile/settings-mobile.component';
import { BookLocalComponent } from './views/booking/book-local/book-local.component';
import { InviteUsersComponent } from './views/gestion/invite-users/invite-users.component';
import { GestionState } from './views/gestion/store/gestion.state';
import { UtilsService } from './services/utils.service.ts';
import { ChartsModule } from 'ng2-charts';
import { TableLocalComponent } from './views/booking/table-local/table-local.component';
import { TablePadelComponent } from './views/booking/table-padel/table-padel.component';
import { TableTenisComponent } from './views/booking/table-tenis/table-tenis.component';
import { TablePiscinaComponent } from './views/booking/table-piscina/table-piscina.component';
import { TableSolariumComponent } from './views/booking/table-solarium/table-solarium.component';
import { DocumentsComponent } from './views/documents/documents.component';
import { DocumentoComponent } from './views/documents/documento/documento.component';
import { FileSizePipe } from './common/pipes/filesize.pipe';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { ImageZoomModule } from 'angular2-image-zoom';
import { PlanBlockedComponent } from './components/plan-blocked/plan-blocked.component';
import { OfertasComponent } from './views/admin/ofertas/ofertas.component';
import { NuevaOfertaComponent } from './views/admin/ofertas/nueva-oferta/nueva-oferta.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CompanySettingsComponent } from './components/settings/company-settings/company-settings.component';
import { UsersSettingsComponent } from './components/settings/users-settings/users-settings.component';
import { ReservasSettingsComponent } from './components/settings/reservas-settings/reservas-settings.component';
import { PermissionsSettingsComponent } from './components/settings/permissions-settings/permissions-settings.component';
import { AdminUserAuthInterceptor } from './common/interceptor/admin-user-auth.interceptor';
import { SessionExpiredComponent } from './components/session-expired/session-expired.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';

/* import {
UploadOutlined
} from '@ant-design/icons';

const icons: [] = [
  UploadOutlined
]; */
const appRoutes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'noticias', component: NoticiasComponent, canActivate: [AuthGuard] },
  { path: 'incidencias', component: IncidenciasComponent, canActivate: [AuthGuard] },
  { path: 'gestion', component: GestionComponent, canActivate: [AuthGuard] },
  { path: 'booking', component: BookingComponent, canActivate: [AuthGuard] },
  { path: 'documents', component: DocumentsComponent, canActivate: [AuthGuard] },
  { path: 'thor', component: AdminComponent, canActivate: [AdminAuthGuard] },
  { path: 'logout', component: LogoutComponent, canActivate: [AuthGuard] }
];

/* var config = {
  apiKey: 'AIzaSyCdQZ40oiJvcK0HZDsCtW7YB83H3PJpx1E',
  authDomain: 'icomunity-4a5ff.firebaseapp.com',
  databaseURL: 'https://icomunity-4a5ff.firebaseio.com',
  projectId: 'icomunity-4a5ff',
  storageBucket: 'icomunity-4a5ff.appspot.com',
  messagingSenderId: '634760725285'
}; */

@NgModule({
  declarations: [
    AppComponent,
    NoticiasComponent,
    LogoutComponent,
    HomeComponent,
    LoginComponent,
    FooterComponent,
    HeaderComponent,
    IncidenciasComponent,
    ComunidadComponent,
    DialogLogoutComponent,
    GestionComponent,
    BookingComponent,
    ServiceModalComponent,
    PhoneModalComponent,
    ValidationModalComponent,
    MessageModalComponent,
    NoDataComponent,
    LoaderComponent,
    ContactSupportComponent,
    IncidenciaComponent,
    NoticiaComponent,
    BookComponent,
    BookLocalComponent,
    AddPhoneComponent,
    AddServiceComponent,
    EditPhoneComponent,
    EditServiceComponent,
    UserDetailComponent,
    NewIncidenciaComponent,
    TraspasoIncidenciaComponent,
    NewNoticiaComponent,
    AddDocumentComponent,
    AdminComponent,
    TicketsComponent,
    ManageComponent,
    SettingsWebComponent,
    SettingsMobileComponent,
    InviteUsersComponent,
    TableLocalComponent,
    TablePadelComponent,
    TableTenisComponent,
    TablePiscinaComponent,
    TableSolariumComponent,
    DocumentsComponent,
    DocumentoComponent,
    FileSizePipe,
    ImageViewerComponent,
    PlanBlockedComponent,
    OfertasComponent,
    NuevaOfertaComponent,
    SettingsComponent,
    CompanySettingsComponent,
    UsersSettingsComponent,
    ReservasSettingsComponent,
    PermissionsSettingsComponent,
    SessionExpiredComponent,
    SidenavComponent
  ],
  imports: [
    MatSnackBarModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatCardModule,
    MatMenuModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatTableModule,
    MatSelectModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgProgressModule,
    NzFormModule,
    NzUploadModule,
    NzIconModule,
    NzToolTipModule,
    NzNotificationModule,
    NzModalModule,
    NzSwitchModule,
    NzTagModule,
    NzTableModule,
    NzDividerModule,
    NzLayoutModule,
    NzDatePickerModule,
    NzRadioModule,
    NzCheckboxModule,
    NzSelectModule,
    ChartsModule,
    ImageZoomModule,
    SweetAlert2Module.forRoot(),
    RouterModule.forRoot(appRoutes, { useHash: true }),
    NgxsModule.forRoot([CoreState, GestionState], {
      developmentMode: !environment.production
    }),
    NgxsStoragePluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsRouterPluginModule.forRoot()
    // firebase.initializeApp(config)
  ],
  entryComponents: [DialogLogoutComponent],

  providers: [
    DatePipe,
    FileSizePipe,
    HttpService,
    AuthService,
    AlertService,
    ExporterService,
    SessionService,
    ModalService,
    StorageService,
    AuthGuard,
    AdminAuthGuard,
    UtilsService,
    { provide: NZ_I18N, useValue: es_ES },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AdminUserAuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingSpinnerInterceptor,
      multi: true
    },
    { provide: BrowserXhr, useClass: NgProgressBrowserXhr }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
