import { Injectable } from '@angular/core';
import { SessionService } from '../../services/session/session.service';
import { ActivatedRouteSnapshot,  Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable()
export class AuthGuard implements CanActivate {
    path: ActivatedRouteSnapshot[];
    route: ActivatedRouteSnapshot;
  constructor(
      private router: Router,
      private sessionService: SessionService,
  ) {}

  canActivate(): Observable<boolean> | boolean {
    if (this.sessionService.isLoggedIn()) {
        return true;
    } else {
        this.router.navigate(['login']);
        return false;
    }
  }
}
