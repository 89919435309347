import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../services/modal/modal.service';

@Component({
  selector: 'app-validation-modal',
  templateUrl: './validation-modal.component.html',
  styleUrls: ['./validation-modal.component.scss']
})
export class ValidationModalComponent implements OnInit {
  @Input() message : String;
  constructor(public modalService:ModalService) { }
  ngOnInit() {

  }

}
