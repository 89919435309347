import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import swal from 'sweetalert2';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable()

export class AlertService {
 private subject = new Subject<any>();
    constructor(
        private notification: NzNotificationService
        ) {
    }

    popNotification(type: string, title: string, content: string): void {
        this.notification
          .create(
              type,
                title,
                content,
          );
      }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    alertProFeature(feature:String){
        let message = 'Para poder '+feature+' contáctanos y pasa a la versión PRO';
        swal(
            '¡ HAZTE PRO !',
             message,
            'info'
          );
    }
}
