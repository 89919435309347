export const environment = {
  production: false,
  defaultLang: 'es',
  version: '2023.01.31',
  soporteEmail: 'soporte.icomunity@gmail.com',
  sentryDSN: 'https://f9818f66c6664f2881714cb2bde69c97@logentry.kiwimobapp.com/3',
  // migrationApiUrl: 'http://localhost:4080/api/v1',
  migrationApiUrl: 'https://backbone.berekstan.com:4081/api/v1',
  // migrationApiUrlV2: 'http://localhost:4080/api/v2',
  migrationApiUrlV2: 'https://backbone.berekstan.com:4081/api/v2',
  apiUrl: 'https://backbone.berekstan.com:4432',
  apiUrlAccount: 'https://backbone.berekstan.com:4432',
  apiUrlCore: 'https://backbone.berekstan.com:4433/core',
  apiUrlZeus: 'https://backbone.berekstan.com:4434',
  apiUrlPush: 'https://backbone.berekstan.com:4431/push',
  apiUrlBookingLocal: 'https://backbone.berekstan.com:4439/booking',
  apiUrlBookingPadel: 'https://backbone.berekstan.com:4430/booking',
  apiUrlBookingPiscina: 'https://backbone.berekstan.com:4490/booking',
  apiUrlBookingSolarium: 'https://backbone.berekstan.com:4492/booking',
  assetsUrl: 'http://localhost:3131/file-uploader-folder',
  // apiMailingAuthUrl: 'https://backbone.berekstan.com:5081/api/v1/auth',
  apiMailingAuthUrl: 'http://localhost:5080/api/v1/auth',
  apiMailingUrl: 'https://backbone.berekstan.com:5081/api/v1/mailing',
  // apiMailingUrl: 'http://localhost:5080/api/v1/mailing',
  mailingAccess: { user: 'mailingaccess@icomunity.com', password: 'D@SDJHSb3123127984631--cf', key: 'pMX*UVaTDkKKG2dJKvxd$30@*rvZUw' },
  assetsLinks: {
    defaultCompanyLogoUrl: 'https://filerun.sedev.es/wl/?id=NG8QNr63MrkzZv7zbE1jdINDqlfrF3Wl&fmode=open'
  }
};
