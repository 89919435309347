import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { StorageService } from '../../services/storage/storage.service';
import { ServiceModel } from '../../models/service.model';
import { PhoneModel } from '../../models/phone.model';
import { ModalService } from '../../services/modal/modal.service';
import { AlertService } from '../../services/alert/alert.service';
import { ComunidadModel } from '../../model/comunidad.model';
import { UserModel } from '../../model/vecino.model';
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { GetPhones, GetServices, GetUsers, UpdateUsersState } from '../home/store/home.actions';
import { NzModalService } from 'ng-zorro-antd';
import { DatePipe } from '@angular/common';
import { ExporterService } from '../../services/exporter/exporter.service';

@Component({
  selector: 'app-gestion',
  templateUrl: './gestion.component.html',
  styleUrls: ['./gestion.component.scss']
})
export class GestionComponent implements OnInit {
  @Select((state) => state.core.users) users$: Observable<any>;
  @Select((state) => state.core.services) services$: Observable<any>;
  @Select((state) => state.core.phones) phones$: Observable<any>;
  @Select((state) => state.core.selectedComunity) selectedComunity$: Observable<any>;
  fileIcon = 'assets/img/file.png';
  arrowIcon = 'assets/img/arrow.png';
  refreshIcon = 'assets/img/refresh.png';
  userIcon = 'assets/img/user-add.png';
  form: FormGroup;
  loading = false;
  selectedComunidad: ComunidadModel;
  users = [];
  filteredUsers = [];
  sortedUsers = [];
  usersExport = [];
  users_clean = [];
  users_all = [];
  users_locked = [];
  users_deleted = [];
  columns_select = ['select', 'position', 'name', 'email', 'phone', 'adress', 'actions'];
  service_columns_select = ['position', 'state', 'type', 'name', 'phone', 'actions'];
  phone_columns_select = ['position', 'state', 'name', 'phone', 'actions'];
  columns = ['position', 'name', 'email', 'phone', 'adress'];
  displayedColumns = [];
  serviceDisplayedColumns = [];
  phoneDisplayedColumns = [];
  selection = new SelectionModel<Element>(true, []);
  selected_users = [];
  actions = [];
  actions_all = ['bloquear', 'suprimir'];
  actions_locked = ['desbloquear', 'suprimir'];
  states = ['todos', 'activos', 'bloqueados', 'suprimidos'];
  bool_state = 0;
  bool_action = 0;
  // disabled
  disabled_state: boolean[] = [true, false, false];
  serviceItem: ServiceModel;
  phoneItem: PhoneModel;
  // ICONS
  addIcon = 'assets/icon/add_white.png';
  addIconDoc = 'assets/img/add.png';
  closeIcon = 'assets/img/close.png';
  orderItems = [
    { label: 'Por nombre', value: 'name' },
    { label: 'Por email', value: 'email' },
    { label: 'Por dirección', value: 'adress' }
  ];
  // SERVICES
  serviceList: ServiceModel[] = [];
  // PHONES
  phoneList: PhoneModel[] = [];

  // SPECIAL FEATURES FOR PAYMENT RELEASE
  payment = false;
  selectedUser: UserModel;

  selectedComunity: ComunidadModel;
  showInviteUser = false;
  searchValue = '';
  checked = false;
  indeterminate = false;
  setOfCheckedId = new Set<number>();
  selectedActionOption = null;
  selectedSortOption = null;
  selectedStateOption = 'todos';

  constructor(
    private httpService: HttpService,
    public storageService: StorageService,
    public alertService: AlertService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    public modalService: ModalService,
    public exporterService: ExporterService,
    private store: Store,
    private modal: NzModalService
  ) {
    this.modalService.hideServiceModal();
    this.modalService.hidePhoneModal();
  }

  ngOnInit() {
    this.displayedColumns = this.columns_select;
    this.serviceDisplayedColumns = this.service_columns_select;
    this.phoneDisplayedColumns = this.phone_columns_select;
    this.actions = this.actions_all;
    this.init();
  }
  clearFilters() {
    this.selectedActionOption = null;
    this.selectedSortOption = null;
    this.selectedStateOption = 'todos';
    this.filteredUsers = this.users_all;
  }

  refresh() {
    this.clearFilters();
    this.store.dispatch(new GetUsers());
    this.store.dispatch(new GetServices());
    this.store.dispatch(new GetPhones());
  }
  init() {
    this.selectedComunity$.subscribe((selectedComunity) => {
      if (selectedComunity) {
        this.selectedComunity = selectedComunity;
      }
    });
    this.users$.subscribe((users) => {
      if (users && users.length > 0) {
        this.filteredUsers = Object.assign([]);
        this.users = Object.assign([]);
        this.usersExport = this.removeDuplicates(users, 'phone');
        this.usersExport = this.removeDuplicates(this.usersExport, 'email');
        this.users = this.usersExport;
        this.users_all = this.usersExport;
        this.prepareUserData();
      } else {
        this.filteredUsers = Object.assign([]);
        this.users = Object.assign([]);
        this.users_all = [];
      }
    });
    /*  this.services$.subscribe((services) => {
      if (services && services.length > 0) {
        this.serviceList = services;
        this.serviceDataSource = new MatTableDataSource(this.serviceList);
      } else {
        this.serviceList = null;
        this.serviceDataSource = new MatTableDataSource([]);
      }
    });
    this.phones$.subscribe((phones) => {
      if (phones && phones.length > 0) {
        this.phoneList = phones;
        this.phoneDataSource = new MatTableDataSource(this.phoneList);
      } else {
        this.phoneList = null;
        this.phoneDataSource = new MatTableDataSource([]);
      }
    }); */
  }
  prepareUserData() {
    this.formatUsers();
    this.filterTestUsers();
    this.sortUsersByProperty('name');
    this.filterCleanUser();
    this.filterLockedUser();
    this.filterDeletedUser();
    this.loadTable();
  }
  popInviteUser() {
    this.showInviteUser = true;
  }
  onSortUsers(sortValue: string) {
    /*     this.filterCleanUser();
    this.filterLockedUser();
    this.filterDeletedUser();
    this.loadTable(); */
    this.sortUserTable(sortValue);
  }
  removeDuplicates(originalArray, prop) {
    const newArray = [];
    const lookupObject = {};
    // tslint:disable-next-line: forin
    for (const i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    // tslint:disable-next-line: forin
    for (const i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  validationCallback(response) {
    console.log('validation -> ', response);
  }
  sortUsersByProperty(property: string) {
    this.users.sort(function(a, b) {
      if (a[`${property}`] < b[`${property}`]) {
        return -1;
      }
      if (a[`${property}`] > b[`${property}`]) {
        return 1;
      }
      return 0;
    });
  }

  sortUserTable(property) {
    this.filteredUsers.sort(function(a, b) {
      if (a[`${property}`] < b[`${property}`]) {
        return -1;
      }
      if (a[`${property}`] > b[`${property}`]) {
        return 1;
      }
      return 0;
    });
  }

  exportar() {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: 'Va a exportar la lista de usuarios con el formato excel',
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        try {
          const filename = 'usuarios';
          const clonedList = this.storageService.cloneObject(this.usersExport);
          const data: any[] = this.mapUsersForExport(clonedList);
          this.exporterService.exportAsExcelFile(data, filename);
        } catch (error) {}
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }
  mapUsersForExport(data: any[]) {
    const result = [];
    for (const item of data) {
      if (
        !item.name.includes('test') &&
        !item.name.includes('soporte') &&
        !item.name.includes('icomunity') &&
        !item.email.includes('test') &&
        !item.email.includes('soporte') &&
        !item.email.includes('icomunity')
      ) {
        item.comunidad = this.selectedComunity.name;
        item.email = item.email;
        item.nombre = item.name;
        item.telefono = item.phone;
        item.direccion = `${item.adress} ${item.planta} ${item.door}`;
        item.rgpd = item.rgpd === 1 ? 'aceptado' : 'pendiente';
        item.estado = item.state === 0 ? 'activo' : 'inactivo';
        item.fechaalta = this.formatDate(item.created_at);

        delete item.adress;
        delete item.planta;
        delete item.door;
        delete item.name;
        delete item.state;
        delete item.id;
        delete item.state;
        delete item.phone;
        delete item.created_at;
        delete item.code_house;
        delete item.code_comunity;
        delete item.bool_informacion;
        delete item.bool_incidencia;
        delete item.bool_reserva;
        delete item.auth_token;
        delete item.fb_token;

        result.push(item);
      } else {
        console.log('BOOM ITEM  -> ', item);
      }
    }
    return result;
  }
  formatDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
  }
  phoneSaved(phone: PhoneModel) {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: '¿Está seguro que quiere actualizar este teléfono?',
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.httpService.updatePhone(phone).subscribe(
          (response) => {
            this.store.dispatch(new GetPhones());
          },
          (error) => {
            console.log('error ', error);
          }
        );
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }

  serviceSaved(service: ServiceModel) {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: '¿Está seguro que quiere actualizar este servicio?',
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.httpService.updateService(service).subscribe(
          (response) => {
            this.store.dispatch(new GetServices());
          },
          (error) => {
            console.log('error ', error);
          }
        );
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }

  editService(service: ServiceModel) {
    this.serviceItem = service;
    this.modalService.showServiceModal();
  }

  selectUser(user: UserModel) {
    this.selectedUser = user;
  }
  deleteService(service: ServiceModel) {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: '¿Está seguro que quiere suprimir este servicio?',
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.httpService.deleteService(service).subscribe((res) => {
          this.store.dispatch(new GetServices());
        });
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }

  editPhone(phone: PhoneModel) {
    this.phoneItem = phone;
    this.modalService.showPhoneModal();
  }

  deletePhone(phone: PhoneModel) {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: '¿Está seguro que quiere suprimir este teléfono?',
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.httpService.deletePhone(phone).subscribe((res) => {
          this.store.dispatch(new GetPhones());
        });
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }
  addService() {}
  addPhone() {}

  chargeUsersinTable() {
    this.filterCleanUser();
    this.filterLockedUser();
    this.filterDeletedUser();
    this.loadTable();
  }

  stateEvent(state: string) {
    // this.stateSelect.close();
    this.selectedStateOption = state;
    switch (state) {
      case 'todos':
        this.filteredUsers = this.users_all;
        break;
      case 'activos':
        this.filteredUsers = this.users_clean;
        break;
      case 'bloqueados':
        this.filteredUsers = this.users_locked;
        break;
      case 'suprimidos':
        this.filteredUsers = this.users_deleted;
        break;
      default:
        this.filteredUsers = this.users;
        break;
    }
  }

  actionEvent(action: string) {
    try {
      const size = this.setOfCheckedId.size;
      if (size === 0) {
        this.onNothingSelected();
      } else if (size === 1) {
        const list = Array.from(this.setOfCheckedId);
        const user = this.filteredUsers.find((item) => item.id === list[0]);
        const text = 'el usuario ' + user.name;
        this.confirmAction(action, text, [user.id]);
      } else if (size > 1) {
        let text = 'los usuarios : ';
        const list = Array.from(this.setOfCheckedId);
        let index = 0;
        for (const key of list) {
          const user = this.filteredUsers.find((item) => item.id === key);
          if (index === 0) {
            text += user.name;
          } else {
            text += ' , ' + user.name;
          }
          index += 1;
        }
        this.confirmAction(action, text, list);
      }
      this.selectedActionOption = '';
    } catch (error) {
      console.log('error -> ', error);
    }
  }

  onNothingSelected() {
    this.modal.info({
      nzTitle: 'INFORMACIÓN',
      nzContent: 'Tiene que seleccionar al menos un usuario para realizar esta acción',
      nzOkText: 'Ok'
    });
  }

  onSuccess() {
    this.modal.info({
      nzTitle: 'INFORMACIÓN',
      nzContent: 'Operacion realizada con exito',
      nzOkText: 'Ok'
    });
  }
  onFail() {
    this.modal.warning({
      nzTitle: 'INFORMACIÓN',
      nzContent: 'Algo raro ocurro haciendo la reservacion, por favor compruebe otra vez',
      nzOkText: 'Ok'
    });
  }

  confirmAction(action: string, text, list: any[]) {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: `Confirma que desea ${action} ${text}`,
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.updateUserState(list, action);
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }

  filterTestUsers() {
    const result = [];
    for (const item of this.filteredUsers) {
      if (
        !item.name.includes('test') &&
        !item.name.includes('soporte') &&
        !item.name.includes('icomunity') &&
        !item.email.includes('test') &&
        !item.email.includes('soporte') &&
        !item.email.includes('icomunity')
      ) {
        result.push(item);
      }
    }
    this.filteredUsers = result;
  }
  filterLockedUser() {
    this.users_locked = this.users.filter((user) => user.state === 1);
  }

  filterCleanUser() {
    this.users_clean = this.users.filter((user) => user.state === 0);
  }

  filterDeletedUser() {
    this.users_deleted = this.users.filter((user) => user.state === 2);
  }

  updateUserState(id_list, action) {
    this.store.dispatch(new UpdateUsersState(id_list, action));
    this.clearFilters();
  }

  loadTable() {
    this.filteredUsers = [];
    switch (this.selectedStateOption) {
      case 'todos':
        this.filteredUsers = Object.assign([], this.users_all);
        break;
      case 'activos':
        this.filteredUsers = Object.assign([], this.users_clean);
        break;
      case 'bloqueados':
        this.filteredUsers = Object.assign([], this.users_locked);
        break;
      case 'suprimidos':
        this.filteredUsers = Object.assign([], this.users_deleted);
        break;
    }
  }

  resetSearch(): void {
    this.searchValue = '';
    this.search('');
  }

  search(value: string): void {
    this.searchValue = value;
    setTimeout(() => {
      this.filteredUsers = this.users.filter((item: UserModel) =>
        item.name
          .trim()
          .toLowerCase()
          .includes(this.searchValue.trim().toLowerCase())
      );
    }, 500);
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.filteredUsers.filter((user) => !user.disabled);
    this.checked = listOfEnabledData.every(({ id }) => this.setOfCheckedId.has(id));
    this.indeterminate = listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
  }

  onItemChecked(id: number, event): void {
    this.updateCheckedSet(id, event.checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(event): void {
    if (event.checked) {
      this.filteredUsers.forEach(({ id }) => this.updateCheckedSet(id, true));
      this.refreshCheckedStatus();
    } else {
      this.filteredUsers.forEach(({ id }) => this.updateCheckedSet(id, false));
      this.refreshCheckedStatus();
    }
  }

  formatUsers() {
    try {
      if (this.users && this.users.length > 0) {
        for (const user of this.users) {
          const item = Object.assign({}, user, { disabled: false });
          this.filteredUsers.push(item);
        }
      }
    } catch (error) {
      console.log('error -> ', error);
    }
  }
}
