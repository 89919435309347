import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AdminUserModel } from '../../../model/admin-user.model';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss']
})
export class CompanySettingsComponent implements OnInit {
  @Select((state) => state.core.adminUser) adminUser$: Observable<any>;
  adminUser: AdminUserModel;
  companyLogoPlaceholder = environment.assetsLinks.defaultCompanyLogoUrl;

  constructor() {}

  ngOnInit() {
    this.adminUser$.subscribe((adminUser) => {
      if (adminUser) {
        this.adminUser = adminUser;
      }
    });
  }
}
