import { HttpErrorResponse } from '@angular/common/http';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { forkJoin, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ReservaHoursDto } from '../../../common/dtos/reserva-hours.dto';
import { AdminUserModel } from '../../../model/admin-user.model';
import { CompanyModel } from '../../../model/company.model';
import { ComunidadModel } from '../../../model/comunidad.model';
import { HelpTicketModel } from '../../../model/help-ticket.model';
import { IncidenciaModel } from '../../../model/incidencia.model';
import { LocalBookModel } from '../../../model/local-book.model';
import { NoticiaModel } from '../../../model/noticia.model';
import { PadelBookModel } from '../../../model/padel-book.model';
import { PhoneModel } from '../../../model/phone.model';
import { ServiceModel } from '../../../model/service.model';
import { TokenV2Model } from '../../../model/token-v2.model';
import { UserModel } from '../../../model/vecino.model';
import { OfertaModel } from '../../../models/oferta.model';
import { PiscinaModel } from '../../../models/piscina.mode';
import { SolariumModel } from '../../../models/solarium.model';
import { AlertService } from '../../../services/alert/alert.service';
import { HttpService } from '../../../services/http/http.service';
import { ModalService } from '../../../services/modal/modal.service';
import { SessionService } from '../../../services/session/session.service';
import {
  AddDocument,
  AddNoticia,
  AfterAddDocument,
  CancelReservaLocal,
  ChristmasAnimationActive,
  ClearAllStores,
  CreateOferta,
  DeleteDocument,
  DeleteOferta,
  DeleteTicketMessage,
  DisableNoticia,
  DismissImageViewer,
  GetDocumentsByType,
  GetFilesTypes,
  GetHelpTickets,
  GetIncidencias,
  GetLocals,
  GetNoticias,
  GetOfertas,
  GetOfertasTypes,
  GetPadels,
  GetPhones,
  GetPiscinas,
  GetServices,
  GetSolariums,
  GetStats,
  GetUsers,
  PopImageViewer,
  PopNewIncidencia,
  PopNewNoticia,
  SaveAuthToken,
  SaveComunities,
  SelectComunity,
  SelectIncidencia,
  SelectTraspasoIncidencia,
  SendTicketMessage,
  UpdateIncidencia,
  UpdateTicket,
  UpdateUsersState,
  SaveTokenV2,
  SaveAdminUser,
  MyManagementData,
  Logout,
  CreateAdminUser,
  UpdateLocalRequest,
  MyComunities,
  UpdatePadelRequest,
  UpdateTenisRequest,
  DeleteAdminUser,
  GetComunityExtras,
  SendUpdatePasswordEmail,
  SetForfait
} from './home.actions';

export class CoreStateModel {
  incidencias: IncidenciaModel[];
  noticias: NoticiaModel[];
  services: ServiceModel[];
  phones: PhoneModel[];
  users: UserModel[];
  locals: LocalBookModel[];
  padels: PadelBookModel[];
  piscinas: PiscinaModel[];
  solariums: SolariumModel[];
  comunities: ComunidadModel[];
  helpTickets: HelpTicketModel[];
  documents: [];
  filesTypes: string[];
  selectedComunity: ComunidadModel;
  selectedIncidencia: IncidenciaModel;
  selectedTraspasoIncidencia: IncidenciaModel;
  adminUser: AdminUserModel;
  authToken: any;
  tokenV2: TokenV2Model;
  popNewNoticia: boolean;
  popNewIncidencia: boolean;
  afterAddDocument: boolean;
  christmasAnimationActive: boolean;
  stats: any;
  imageViewerUrl: string;
  ofertas: OfertaModel[];
  ofertasTypes: any[];
  companies: CompanyModel[];
  availableRoles: string[];
  availablePages: string[];
  availableHours: ReservaHoursDto[];
  companyUsers: AdminUserModel[];
  extras: any;
  forfait: string;
}
export const CoreStateDefaults: CoreStateModel = {
  incidencias: null,
  noticias: null,
  services: null,
  phones: null,
  users: null,
  locals: null,
  padels: null,
  piscinas: null,
  solariums: null,
  comunities: null,
  helpTickets: null,
  filesTypes: null,
  documents: null,
  selectedComunity: null,
  selectedIncidencia: null,
  selectedTraspasoIncidencia: null,
  adminUser: null,
  authToken: null,
  tokenV2: null,
  popNewNoticia: null,
  popNewIncidencia: null,
  afterAddDocument: null,
  christmasAnimationActive: true,
  stats: null,
  imageViewerUrl: null,
  ofertas: null,
  ofertasTypes: null,
  companies: null,
  availableRoles: null,
  availablePages: null,
  companyUsers: null,
  availableHours: [],
  extras: null,
  forfait: ''
};

@State<CoreStateModel>({
  name: 'core',
  defaults: CoreStateDefaults
})
export class CoreState {
  constructor(private http: HttpService, private store: Store, private alertService: AlertService, private sessionService: SessionService, private modalService: ModalService) {}

  get selectedComunity() {
    return this.selectedComunity;
  }

  @Action(Logout)
  logout(ctx: StateContext<CoreStateModel>) {
    ctx.patchState({
      incidencias: null,
      noticias: null,
      services: null,
      phones: null,
      users: null,
      locals: null,
      padels: null,
      piscinas: null,
      solariums: null,
      comunities: null,
      helpTickets: null,
      filesTypes: null,
      documents: null,
      selectedComunity: null,
      selectedIncidencia: null,
      selectedTraspasoIncidencia: null,
      adminUser: null,
      authToken: null,
      tokenV2: null,
      popNewNoticia: null,
      popNewIncidencia: null,
      afterAddDocument: null,
      christmasAnimationActive: true,
      stats: null,
      imageViewerUrl: null,
      ofertas: null,
      ofertasTypes: null,
      companies: null,
      availableRoles: null,
      availablePages: null,
      availableHours: null,
      companyUsers: null
    });
    this.sessionService.logout();
    this.modalService.clearAll();
  }

  @Action(MyComunities)
  myComunities(ctx: StateContext<CoreStateModel>, { userId }) {
    return this.http.requestMyComunities(userId).pipe(
      tap((result: any) => {
        ctx.patchState({
          comunities: result ? result : []
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(UpdateLocalRequest)
  updateLocalRequest(ctx: StateContext<CoreStateModel>, { dto }) {
    return this.http.requestUpdateLocal(dto).pipe(
      tap((result: any) => {
        this.alertService.popNotification('success', 'Administración Comunidades', 'Configuración - Local - actualizada');
        this.store.dispatch(new MyComunities(dto.currentUserId));
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(UpdatePadelRequest)
  updatePadelRequest(ctx: StateContext<CoreStateModel>, { dto }) {
    return this.http.requestUpdatePadel(dto).pipe(
      tap((result: any) => {
        this.alertService.popNotification('success', 'Administración Comunidades', 'Configuración - Padel - actualizada');
        this.store.dispatch(new MyComunities(dto.currentUserId));
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(UpdateTenisRequest)
  updateTenisRequest(ctx: StateContext<CoreStateModel>, { dto }) {
    return this.http.requestUpdateTenis(dto).pipe(
      tap((result: any) => {
        this.alertService.popNotification('success', 'Administración Comunidades', 'Configuración - Tenis - actualizada');
        this.store.dispatch(new MyComunities(dto.currentUserId));
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(CreateAdminUser)
  createAdminUser(ctx: StateContext<CoreStateModel>, { dto }) {
    return this.http.requestCreateAdminUser(dto).pipe(
      tap((result: any) => {
        this.store.dispatch(new MyManagementData(dto.currentUserId));
        this.alertService.popNotification('success', 'Administración Usuarios', 'Usuario creado');
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  @Action(DeleteAdminUser)
  deleteAdminUser(ctx: StateContext<CoreStateModel>, { dto }) {
    return this.http.requestDeleteAdminUser(dto).pipe(
      tap((result: any) => {
        this.store.dispatch(new MyManagementData(dto.currentUserId));
        this.alertService.popNotification('success', 'Administración Usuarios', 'Usuario suprimido');
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  @Action(SendUpdatePasswordEmail)
  sendUpdatePasswordEmail(ctx: StateContext<CoreStateModel>, { dto }) {
    return this.http.sendResetPasswordEmail(dto).pipe(
      tap((result: any) => {
        this.alertService.popNotification('success', 'Enlace de reinicio enviado', 'Espere unos minutos y revise su correo electrónico');
        this.store.dispatch(new MyComunities(dto.currentUserId));
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(MyManagementData)
  myManagementData(ctx: StateContext<CoreStateModel>, { userId }) {
    return this.http.getMyManagementData(userId).pipe(
      tap((result: any) => {
        ctx.patchState({
          availablePages: result ? result.availablePages : [],
          availableRoles: result ? result.availableRoles : [],
          companyUsers: result ? result.companyUsers : [],
          availableHours: result ? result.availableHours : null,
          companies: result ? result.companies : []
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(PopImageViewer)
  popImageViewer(ctx: StateContext<CoreStateModel>, { url }) {
    ctx.patchState({
      imageViewerUrl: url ? url : ''
    });
  }

  @Action(DismissImageViewer)
  dismissImageViewer(ctx: StateContext<CoreStateModel>) {
    ctx.patchState({
      imageViewerUrl: null
    });
  }

  @Action(GetStats)
  getStats(ctx: StateContext<CoreStateModel>) {
    forkJoin(
      this.http.getCoreStats(ctx.getState().selectedComunity.code),
      this.http.getLocalStats(ctx.getState().selectedComunity.code),
      this.http.getPadelStats(ctx.getState().selectedComunity.code),
      this.http.getSolariumStats(ctx.getState().selectedComunity.code),
      this.http.getPiscinaStats(ctx.getState().selectedComunity.code)
    ).subscribe(
      (result) => {
        if (result) {
          const parsed = {
            core: result[0].result[0],
            local: result[1].result,
            padel: result[2].result,
            solarium: result[3].result,
            piscina: result[4].result
          };
          ctx.patchState({
            stats: result ? parsed : null
          });
        }
      },
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(GetOfertasTypes)
  getOfertasTypes(ctx: StateContext<CoreStateModel>) {
    return this.http.getOfertasTypes().pipe(
      tap((result: any) => {
        ctx.patchState({
          ofertasTypes: result ? result : []
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(GetOfertas)
  getOfertas(ctx: StateContext<CoreStateModel>) {
    return this.http.getOfertas().pipe(
      tap((result: any) => {
        ctx.patchState({
          ofertas: result ? result : []
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(CreateOferta)
  createOferta(ctx: StateContext<CoreStateModel>, { payload }) {
    return this.http.createOferta(payload).pipe(
      tap((result: any) => {
        this.store.dispatch(new GetOfertas());
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(DeleteOferta)
  deleteOferta(ctx: StateContext<CoreStateModel>, { payload }) {
    return this.http.deleteOferta(payload).pipe(
      tap((result: any) => {
        this.store.dispatch(new GetOfertas());
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(GetNoticias)
  getNoticias(ctx: StateContext<CoreStateModel>) {
    return this.http.doFetchNoticias(ctx.getState().selectedComunity.code).pipe(
      tap((result: any) => {
        ctx.patchState({
          noticias: result ? result.result : null
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }
  @Action(GetIncidencias)
  getIncidencias(ctx: StateContext<CoreStateModel>) {
    return this.http.doFetchIncidencias(ctx.getState().selectedComunity.code).pipe(
      tap((result: any) => {
        ctx.patchState({
          incidencias: result ? result.result : null
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }
  @Action(CancelReservaLocal)
  cancelReservaLocal(ctx: StateContext<CoreStateModel>, { payload }) {
    return this.http.cancelReservaLocalAdmin(payload).pipe(
      tap((result: any) => {
        return result;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }
  @Action(GetDocumentsByType)
  getDocumentsByType(ctx: StateContext<CoreStateModel>, { type }) {
    return this.http.getDocumentsByType(ctx.getState().selectedComunity.code, type).pipe(
      tap((result: any) => {
        ctx.patchState({
          documents: result ? result : []
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(GetFilesTypes)
  getFilesTypes(ctx: StateContext<CoreStateModel>) {
    return this.http.getFilesTypes().pipe(
      tap((result: any) => {
        ctx.patchState({
          filesTypes: result
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }
  @Action(GetUsers)
  getUsers(ctx: StateContext<CoreStateModel>) {
    return this.http.doFetchUsers(ctx.getState().selectedComunity.code).pipe(
      tap((result: any) => {
        ctx.patchState({
          users: result ? result.result : null
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }
  @Action(GetServices)
  getServices(ctx: StateContext<CoreStateModel>) {
    return this.http.doFetchServices(ctx.getState().selectedComunity.code).pipe(
      tap((result: any) => {
        ctx.patchState({
          services: result ? result : null
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }
  @Action(GetPhones)
  getPhones(ctx: StateContext<CoreStateModel>) {
    return this.http.doFetchPhones(ctx.getState().selectedComunity.code).pipe(
      tap((result: any) => {
        ctx.patchState({
          phones: result ? result : null
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }
  @Action(GetLocals)
  getLocals(ctx: StateContext<CoreStateModel>) {
    return this.http.doFetchReservasLocal(ctx.getState().selectedComunity.code).pipe(
      tap((result: any) => {
        ctx.patchState({
          locals: result
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }
  @Action(GetPiscinas)
  getPiscinas(ctx: StateContext<CoreStateModel>) {
    return this.http.doFetchReservasPiscina(ctx.getState().selectedComunity.code).pipe(
      tap((result: any) => {
        ctx.patchState({
          piscinas: result
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }
  @Action(GetSolariums)
  getSolariums(ctx: StateContext<CoreStateModel>) {
    return this.http.doFetchReservasSolarium(ctx.getState().selectedComunity.code).pipe(
      tap((result: any) => {
        ctx.patchState({
          solariums: result
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }
  @Action(GetPadels)
  getPadels(ctx: StateContext<CoreStateModel>) {
    return this.http.doFetchReservasPadel(ctx.getState().selectedComunity.code).pipe(
      tap((result: any) => {
        ctx.patchState({
          padels: result
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }
  @Action(ClearAllStores)
  clearAllStores(ctx: StateContext<CoreStateModel>) {
    this.store.reset(CoreStateDefaults);
    /*     ctx.patchState({
      incidencias: null,
      noticias: null,
      services: null,
      phones: null,
      users: null,
      locals: null,
      padels: null,
      piscinas: null,
      solariums: null,
      comunities: null,
      helpTickets: null,
      filesTypes: null,
      documents: null,
      selectedComunity: null,
      selectedIncidencia: null,
      selectedTraspasoIncidencia: null,
      adminUser: null,
      authToken: null,
      popNewNoticia: null,
      popNewIncidencia: null,
      afterAddDocument: null
    }); */
  }
  @Action(UpdateIncidencia)
  updateIncidencia(ctx: StateContext<CoreStateModel>, { code, category, type, id, state, oculto }) {
    return this.http.requestUpdateIncidencia(code, category, type, id, state, oculto).pipe(
      tap((result: any) => {
        ctx.patchState({
          selectedIncidencia: null
        });
        this.store.dispatch(new GetIncidencias());
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(SelectIncidencia)
  selectIncidencia(ctx: StateContext<CoreStateModel>, { incidencia }) {
    ctx.patchState({
      selectedIncidencia: incidencia
    });
  }
  @Action(SelectTraspasoIncidencia)
  selectTraspasoIncidencia(ctx: StateContext<CoreStateModel>, { incidencia }) {
    ctx.patchState({
      selectedTraspasoIncidencia: incidencia
    });
  }
  @Action(SelectComunity)
  selectComunity(ctx: StateContext<CoreStateModel>, { comunity }) {
    ctx.patchState({
      selectedComunity: comunity
    });
    if (comunity && comunity.code && comunity.code !== '') {
      this.store.dispatch(new GetIncidencias());
      this.store.dispatch(new GetNoticias());
      this.store.dispatch(new GetUsers());
      this.store.dispatch(new GetServices());
      this.store.dispatch(new GetPhones());
      this.store.dispatch(new GetLocals());
      this.store.dispatch(new GetPadels());
      this.store.dispatch(new GetPiscinas());
      this.store.dispatch(new GetSolariums());
    }
  }

  @Action(SaveComunities)
  saveComunities(ctx: StateContext<CoreStateModel>, { comunities }) {
    ctx.patchState({
      comunities: comunities
    });
  }

  @Action(SaveAdminUser)
  saveAdminUser(ctx: StateContext<CoreStateModel>, { adminUser }) {
    ctx.patchState({
      adminUser: adminUser
    });
  }

  @Action(SaveAuthToken)
  saveAuthToken(ctx: StateContext<CoreStateModel>, { authToken }) {
    ctx.patchState({
      authToken: authToken
    });
  }
  @Action(SaveTokenV2)
  saveTokenV2(ctx: StateContext<CoreStateModel>, { tokenV2 }) {
    ctx.patchState({
      tokenV2
    });
  }
  @Action(PopNewNoticia)
  popNewNoticia(ctx: StateContext<CoreStateModel>, { show }) {
    ctx.patchState({
      popNewNoticia: show ? true : null
    });
  }
  @Action(PopNewIncidencia)
  popNewIncidencia(ctx: StateContext<CoreStateModel>, { show }) {
    ctx.patchState({
      popNewIncidencia: show ? true : null
    });
  }

  @Action(ChristmasAnimationActive)
  christmasAnimationActive(ctx: StateContext<CoreStateModel>, { active }) {
    ctx.patchState({
      christmasAnimationActive: active
    });
  }
  @Action(AfterAddDocument)
  afterAddDocument(ctx: StateContext<CoreStateModel>, { action }) {
    ctx.patchState({
      afterAddDocument: action
    });
  }
  @Action(AddDocument)
  addDocument(ctx: StateContext<CoreStateModel>, { document }) {
    const doc = {
      ...document,
      code_comunity: ctx.getState().selectedComunity.code
    };
    return this.http.addDocument(doc).pipe(
      tap((result: any) => {
        this.store.dispatch(new GetDocumentsByType(document.type));
        this.store.dispatch(new AfterAddDocument(true));
        this.alertService.popNotification('success', 'Alta documento', 'Documento dado de alta correctamente');
      }),
      catchError((err: any) => {
        this.alertService.popNotification('error', 'Alta documneto', 'No se ha podido dar de alta al documento');
        return throwError(err);
      })
    );
  }
  @Action(DeleteDocument)
  deleteDocument(ctx: StateContext<CoreStateModel>, { documentId, type }) {
    return this.http.deleteDocument(ctx.getState().selectedComunity.code, documentId).pipe(
      tap((result: any) => {
        this.store.dispatch(new GetDocumentsByType(type));
        this.alertService.popNotification('success', 'Borrar documento', 'Documento borrado correctamente');
      }),
      catchError((err: any) => {
        this.alertService.popNotification('error', 'Borrar documneto', 'No se ha podido borrar el documento');
        return throwError(err);
      })
    );
  }
  @Action(AddNoticia)
  addNoticia(ctx: StateContext<CoreStateModel>, { noticia }) {
    if (ctx.getState().selectedComunity.code && ctx.getState().selectedComunity.code !== '') {
      noticia.code_comunity = ctx.getState().selectedComunity.code;
      return this.http.addNoticiaRequest(noticia).pipe(
        tap((result: any) => {
          this.store.dispatch(new PopNewNoticia(false));
          this.store.dispatch(new GetNoticias());
          this.alertService.popNotification('success', 'Alta noticia', 'Noticia dada de alta correctamente');
        }),
        catchError((err: any) => {
          this.alertService.popNotification('error', 'Alta noticia', 'No se ha podido dar de alta a la noticia');
          return throwError(err);
        })
      );
    } else {
      this.alertService.popNotification('warning', 'Aviso', 'Tiene que seleccionar una comunidad para poder realizar está acción');
    }
  }

  @Action(DisableNoticia)
  disableNoticia(ctx: StateContext<CoreStateModel>, { id }) {
    return this.http.requestUpdateNoticia(ctx.getState().selectedComunity.code, id, 0).pipe(
      tap((result: any) => {
        this.store.dispatch(new GetNoticias());
      }),
      catchError((err: any) => {
        this.alertService.popNotification('error', 'Desactivar noticia', 'No se ha podido desactivar la noticia');
        return throwError(err);
      })
    );
  }

  @Action(UpdateUsersState)
  updateUsersState(ctx: StateContext<CoreStateModel>, { id_list, action }) {
    return this.http.requestUpdateUserState(ctx.getState().selectedComunity.code, id_list, action).pipe(
      tap((result: any) => {
        ctx.patchState({
          selectedIncidencia: null
        });
        this.alertService.popNotification('success', 'ACTUALIZACIÓN', 'Usuarios actualizados con éxito');
        this.store.dispatch(new GetUsers());
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  /*
  .########.##.....##..#######..########.....########.##.....##.##....##..######..########.####..#######..##....##..######.
  ....##....##.....##.##.....##.##.....##....##.......##.....##.###...##.##....##....##.....##..##.....##.###...##.##....##
  ....##....##.....##.##.....##.##.....##....##.......##.....##.####..##.##..........##.....##..##.....##.####..##.##......
  ....##....#########.##.....##.########.....######...##.....##.##.##.##.##..........##.....##..##.....##.##.##.##..######.
  ....##....##.....##.##.....##.##...##......##.......##.....##.##..####.##..........##.....##..##.....##.##..####.......##
  ....##....##.....##.##.....##.##....##.....##.......##.....##.##...###.##....##....##.....##..##.....##.##...###.##....##
  ....##....##.....##..#######..##.....##....##........#######..##....##..######.....##....####..#######..##....##..######.
  */

  @Action(GetHelpTickets)
  getHelpTickets(ctx: StateContext<CoreStateModel>) {
    return this.http.thorFindHelpTickets().pipe(
      tap((result: any) => {
        ctx.patchState({
          helpTickets: result ? result : null
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(SendTicketMessage)
  sendTicketMessage(ctx: StateContext<CoreStateModel>, { ticketId, origin, target, message, isUser }) {
    return this.http.thorSendTicketMessage(ticketId, origin, target, message, isUser).pipe(
      tap((result: any) => {}),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(DeleteTicketMessage)
  deleteTicketMessage(ctx: StateContext<CoreStateModel>, { messageId }) {
    return this.http.thorDeleteTicketMessage(messageId).pipe(
      tap((result: any) => {
        this.store.dispatch(new GetHelpTickets());
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(UpdateTicket)
  updateTicket(ctx: StateContext<CoreStateModel>, { ticket }) {
    return this.http.thorDeleteTicketMessage(ticket).pipe(
      tap((result: any) => {}),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(GetComunityExtras)
  getComunityExtras(ctx: StateContext<CoreStateModel>) {
    return this.http.getComunityExtras(ctx.getState().selectedComunity.code).pipe(
      tap((result: any) => {
        ctx.patchState({
          extras: result ? result.result : null
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  @Action(SetForfait)
  setForfait(ctx: StateContext<CoreStateModel>, { forfait }) {
    ctx.patchState({
      forfait: forfait || ''
    });
  }
}
