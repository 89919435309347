import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { NzModalService } from 'ng-zorro-antd';
import { Observable } from 'rxjs';
import { AdminUserModel } from '../../../model/admin-user.model';
import { CompanyModel } from '../../../model/company.model';
import { ComunidadModel } from '../../../model/comunidad.model';
import { CreateAdminUser, DeleteAdminUser, SendUpdatePasswordEmail } from '../../../views/home/store/home.actions';

const EMAIL_PATTERN = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
@Component({
  selector: 'app-users-settings',
  templateUrl: './users-settings.component.html',
  styleUrls: ['./users-settings.component.scss']
})
export class UsersSettingsComponent implements OnInit {
  @Select((state) => state.core.adminUser) adminUser$: Observable<any>;
  @Select((state) => state.core.companies) companies$: Observable<any>;
  @Select((state) => state.core.comunities) comunities$: Observable<any>;
  @Select((state) => state.core.companyUsers) companyUsers$: Observable<any>;
  @Select((state) => state.core.availableRoles) availableRoles$: Observable<any>;
  @Select((state) => state.core.availablePages) availablePages$: Observable<any>;
  adminUser: AdminUserModel;
  companyUsers = [];
  companies: CompanyModel[] = [];
  comunities: ComunidadModel[] = [];
  availableRoles = [];
  availablePages = [];
  selectedRoles = [];
  selectedCompany;
  selectedComunities = [];
  selectedCompanyName = null;
  validateForm: FormGroup;
  isManageUsers = false;
  isCreateUser = false;

  constructor(private fb: FormBuilder, private store: Store, private modal: NzModalService) {
    this.validateForm = this.fb.group({
      company: [null, [Validators.required]],
      email: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],
      name: ['', [Validators.required]],
      phone: ['', []],
      mainRole: [null, [Validators.required]],
      canEdit: [false, [Validators.required]],
      roles: [[], [Validators.required]],
      comunities: [[], [Validators.required]]
    });
  }

  get isCreateUserFormValid() {
    return this.validateForm.valid;
  }

  ngOnInit() {
    this.adminUser$.subscribe((adminUser) => {
      if (adminUser) {
        this.adminUser = adminUser;
        this.setupUsersSettingsVisibility();
      }
    });
    this.companyUsers$.subscribe((companyUsers) => {
      if (companyUsers) {
        this.companyUsers = companyUsers;
      }
    });
    this.comunities$.subscribe((comunities) => {
      if (comunities) {
        this.comunities = comunities;
      }
    });
    this.companies$.subscribe((companies) => {
      if (companies) {
        this.companies = companies;
      }
    });
    this.availableRoles$.subscribe((availableRoles) => {
      if (availableRoles) {
        this.availableRoles = availableRoles;
      }
    });
    this.availablePages$.subscribe((availablePages) => {
      if (availablePages) {
        this.availablePages = availablePages;
      }
    });
  }

  onComunityCheckedChange(comunity: ComunidadModel) {
    this.updateComunities(comunity);
  }

  onPageCheckedChange(page) {
    this.updateRoles(page);
  }

  sendResetPassword() {
    if (!this.adminUser) {
      return;
    }
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: `¿ Desea reiniciar su contraseña ? Le enviaremos un email con el enlace de reinicio.`,
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        const dto = {
          currentUserId: this.adminUser.id,
          email: this.adminUser.email,
          userId: this.adminUser.id
        };
        this.store.dispatch(new SendUpdatePasswordEmail(dto));
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }

  createUserRequest() {
    if (this.validateForm.invalid) {
      this.validateForm.markAsDirty();
      return;
    }
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: `¿ Va a crear un nuevo usuario con el rol: ${this.validateForm.get('mainRole').value}?`,
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        const companyId = (this.validateForm.get('company').value as CompanyModel).id;
        const canEdit = this.validateForm.get('canEdit').value;
        if (canEdit) {
          this.updateRoles('EDIT');
        }
        const dto = {
          currentUserId: this.adminUser.id,
          userId: this.adminUser.id,
          companyId,
          ...this.validateForm.value
        };
        this.store.dispatch(new CreateAdminUser(dto));
        this.resetCreateUserForm();
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }

  deleteUserRequest(user: AdminUserModel) {
    if (!user) {
      return;
    }
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: `¿ Va a suprimir el  usuario ${user.name} con el rol: ${user.mainRole}?`,
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        const dto = {
          currentUserId: this.adminUser.id,
          userId: user.id
        };
        this.store.dispatch(new DeleteAdminUser(dto));
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }

  resetCreateUserForm() {
    this.validateForm.reset();
    this.selectedComunities = [];
    this.selectedRoles = [];
    this.isCreateUser = false;
  }
  toggleCreateUser() {
    this.isCreateUser = !this.isCreateUser;
  }
  setupUsersSettingsVisibility() {
    if (this.adminUser.mainRole === 'THOR') {
      this.isManageUsers = true;
    } else if (this.adminUser.mainRole === 'GESTOR ADMIN') {
      this.isManageUsers = true;
      this.selectedCompanyName = this.adminUser.company.name;
      this.setCompany(this.adminUser.company);
    } else {
      this.isManageUsers = false;
      this.selectedCompanyName = this.adminUser.company.name;
      this.disableCompany();
    }
  }

  setCompany(company) {
    this.validateForm.controls.company.patchValue(company);
  }

  setRole(role) {
    this.updateRoles(role);
  }
  updateComunities(comunity: ComunidadModel) {
    const index = this.selectedComunities.findIndex((selCom) => selCom === comunity.code);
    if (index > -1) {
      this.selectedComunities.splice(index, 1);
    } else {
      this.selectedComunities.push(comunity.code);
    }
    this.validateForm.controls.comunities.patchValue(this.selectedComunities);
  }

  updateRoles(role: string) {
    const index = this.selectedRoles.findIndex((selRole) => selRole === role);
    if (index > -1) {
      this.selectedRoles.splice(index, 1);
    } else {
      this.selectedRoles.push(role);
    }
    this.validateForm.controls.roles.patchValue(this.selectedRoles);
  }

  disableCompany() {
    this.validateForm.controls.company.disable();
  }
}
