import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ModalService } from '../../services/modal/modal.service';

@Injectable()
export class LoadingSpinnerInterceptor implements HttpInterceptor {
  constructor(private modalService: ModalService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   this.modalService.showLoadingModal(5000);
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.modalService.hideLoadingModal();
          }
        },
        (_err: any) => {
          // tslint:disable-next-line:no-identical-functions
          this.modalService.hideLoadingModal();
        },
      ),
    );
  }
}
