import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plan-blocked',
  templateUrl: './plan-blocked.component.html',
  styleUrls: ['./plan-blocked.component.scss']
})
export class PlanBlockedComponent implements OnInit {
  blocked = 'assets/img/plan-expired.png';
  constructor() {}

  ngOnInit() {}
}
