import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filesize'
})
export class FileSizePipe implements PipeTransform {
  transform(value: number, args?: any): any {
    let u = 0;
    const s = 1024;
    while (value >= s || -value >= s) {
      value /= s;
      u++;
    }
    return (u ? value.toFixed(1) + ' ' : value) + ' KMGTPEZY'[u] + 'B';
  }
}
