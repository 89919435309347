import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ChristmasAnimationActive } from '../../views/home/store/home.actions';
import * as moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Select((state) => state.core.christmasAnimationActive) christmasActive$: Observable<any>;

  environment = environment;
  christmasActive = true;
  todayDate = null;

  constructor(private store: Store) {}

  ngOnInit() {
    this.christmasActive$.subscribe((christmasActive) => {
      this.christmasActive = christmasActive;
    });
    this.loadTodayDate();
  }

  switchChristmasAnimations(active: boolean) {
    this.store.dispatch(new ChristmasAnimationActive(active));
  }

  loadTodayDate() {
    this.todayDate = moment(new Date())
      .locale(environment.defaultLang)
      .format('ll');
  }
}
