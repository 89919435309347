import { Injectable } from '@angular/core';
import { Http, Headers, Response, URLSearchParams, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch'

@Injectable()
export class AuthService {
    

    
    loginURL:string="https://backbone.berekstan.com:4432/admin/login";

constructor(private http: Http) { }

    login(email: string, password: string) {
  
        return this.http.post(this.loginURL,{
            email:email,
            password:password
        })
            .map(this.extractData)
            .catch(this.handleError);    
    }

    private extractData(res: Response) {
	let body = res.json();
        return body;
    }


    private handleError (error: Response | any) {
	console.error(error.message || error);
	return Observable.throw(error.message || error);
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }
}

interface  Comunity{
  code:string,
  type:string,
  name:string,
  admin_name:string
  admin_email:string,
  admin_phone:string,
  admin_adress:string,
  login:boolean
}
