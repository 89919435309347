import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { AdminUserModel } from '../../model/admin-user.model';

@Injectable()
export class AdminAuthGuard implements CanActivate {
  @Select((state) => state.core.forfait) forfait$: Observable<any>;
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;
  forfait = 'BASIC';

  constructor(private router: Router) {
    this.forfait$.subscribe((forfait) => {
      if (forfait) {
        this.forfait = forfait;
      }
    });
  }

  canActivate(): Observable<boolean> | boolean {
    if (this.forfait === 'SUPER') {
      return true;
    } else {
      return false;
    }
  }
}
