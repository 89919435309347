import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { VecinoModel } from '../../../models/vecino.model';
import { AlertService } from '../../../services/alert/alert.service';
import { AddDocument } from '../../home/store/home.actions';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss']
})
export class AddDocumentComponent implements OnInit {
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onClose = new EventEmitter<boolean>();
  @Select((state) => state.core.filesTypes) filesTypes$: Observable<any>;
  @Select((state) => state.core.users) users$: Observable<any>;
  @Input() selectedType = '';
  validateForm: FormGroup;
  fileUploadUrl = `${environment.migrationApiUrl}/file-uploader/upload-file`;
  filesTypes = [];
  showUsers = false;
  users: VecinoModel[] = [];
  closing = false;
  constructor(private fb: FormBuilder, private store: Store, private alertService: AlertService) {
    this.validateForm = this.fb.group({
      type: [this.selectedType, [Validators.required]],
      name: ['', [Validators.required]],
      fileId: ['', [Validators.required]],
      forComunity: [true, [Validators.required]],
      userIds: [[], []]
    });
  }

  ngOnInit() {
    this.filesTypes$.subscribe((filesTypes) => {
      if (filesTypes && filesTypes.length > 0) {
        this.filesTypes = filesTypes;
      }
      this.validateForm.controls.type.patchValue(this.selectedType);
    });
    this.users$.subscribe((users) => {
      if (users && users.length > 0) {
        this.users = users;
      }
      this.validateForm.controls.type.patchValue(this.selectedType);
    });
  }
  checkUser(userId: number) {
    const userList: number[] = this.validateForm.get('userIds').value;
    const exist = userList.findIndex((item) => item === userId);
    if (exist === -1) {
      userList.push(userId);
    } else {
      userList.splice(exist, 1);
    }
    this.validateForm.controls.userIds.patchValue(userList);
  }
  selectType(type) {
    this.validateForm.controls.type.patchValue(type);
  }
  clickSwitch(): void {
    this.showUsers = !this.validateForm.get('forComunity').value;
  }
  handleChange({ file, fileList }): void {
    const status = file.status;
    if (status !== 'uploading') {
    }
    if (status === 'done') {
      if (file && file.response) {
        this.validateForm.controls.fileId.patchValue(file.response.id);
      }
      this.alertService.popNotification('success', 'Tratamiento archivo', 'Archivo guardado correctamente');
    } else if (status === 'error') {
      this.alertService.popNotification('error', 'Tratamiento archivo', 'Hubo un error guardando el archivo');
    }
  }
  sendDocument(): void {
    this.validateForm.controls.type.patchValue(this.selectedType);
    if (this.validateForm.valid) {
      const dto = {
        type: this.validateForm.get('type').value ? this.validateForm.get('type').value : this.selectedType,
        name: this.validateForm.get('name').value,
        fileId: this.validateForm.get('fileId').value,
        forComunity: this.validateForm.get('forComunity').value,
        userIds: this.validateForm.get('userIds').value
      };
      this.store.dispatch(new AddDocument(dto));
      this.close();
    }
  }
  resetForm(e: MouseEvent): void {
    if (e) {
      e.preventDefault();
    }
    this.validateForm.reset();
  }

  close() {
    this.closingAnimation();
    setTimeout(() => {
      this.resetForm(null);
      this.onClose.emit(true);
    }, 400);
  }

  closingAnimation() {
    this.closing = true;
  }
}
