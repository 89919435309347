export class DocumentModel {
  id: string;
  code_comunity: string;
  name: string;
  type: string;
  fileExt: string;
  fileSize: number;
  notified: boolean;
  active: boolean;
  forComunity: boolean;
  createdAt: Date;
  updatedAt: Date;
  fileId: string;
  publicUrlPath: string;
  users: number[];
  audiencia?: string;
}
