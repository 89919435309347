import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';
import { Select, Store } from '@ngxs/store';
import { NzModalService } from 'ng-zorro-antd';
import { Observable } from 'rxjs';
import { NuevaOfertaDto } from '../../../../common/dtos/nueva-oferta.dto';
import { ComunidadModel } from '../../../../model/comunidad.model';
import { CreateOferta, GetOfertasTypes } from '../../../home/store/home.actions';

@Component({
  selector: 'app-nueva-oferta',
  templateUrl: './nueva-oferta.component.html',
  styleUrls: ['./nueva-oferta.component.scss']
})
export class NuevaOfertaComponent implements OnInit {
  @Select((state) => state.core.selectedComunity) selectedComunity$: Observable<any>;
  @Select((state) => state.core.ofertasTypes) ofertasTypes$: Observable<any>;

  @Output() done = new EventEmitter<Boolean>(false);
  validateForm: FormGroup;
  selectedComunity: ComunidadModel;
  ofertasTypes = [];

  constructor(private fb: FormBuilder, private store: Store, private modal: NzModalService) {
    this.validateForm = this.fb.group({
      type: ['', [Validators.required]],
      clientname: ['', [Validators.required]],
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      imageUrl: ['', [Validators.required]],
      discount: ['', [Validators.required]],
      from: [null, [Validators.required]],
      to: [null, [Validators.required]],
      qrcode: ['', []],
      url: ['', []],
      phone: ['', []]
    });
  }

  ngOnInit() {
    this.selectedComunity$.subscribe((selectedComunity) => {
      if (selectedComunity) {
        this.selectedComunity = selectedComunity;
      }
    });

    this.ofertasTypes$.subscribe((ofertasTypes) => {
      if (ofertasTypes) {
        this.ofertasTypes = ofertasTypes;
      }
    });
    this.store.dispatch(new GetOfertasTypes());
  }

  save(): void {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: `Va a crear una nueva oferta`,
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        if (this.validateForm.valid) {
          const dto: NuevaOfertaDto = {
            ...this.validateForm.value
          };
          this.store.dispatch(new CreateOferta(dto));
          this.close();
        }
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }
  selectType(type) {
    this.validateForm.controls.type.patchValue(type);
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    const value = event.value;
    if (type === 'from') {
      this.validateForm.controls.from.patchValue(value);
    } else if (type === 'to') {
      this.validateForm.controls.to.patchValue(value);
    }
  }
  resetForm(e: MouseEvent): void {
    if (e) {
      e.preventDefault();
    }
    this.validateForm.reset();
  }

  close() {
    this.resetForm(null);
    this.done.emit(true);
  }
}
