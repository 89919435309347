import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PhoneModel } from '../../../models/phone.model';

@Component({
  selector: 'app-edit-phone',
  templateUrl: './edit-phone.component.html',
  styleUrls: ['./edit-phone.component.scss']
})
export class EditPhoneComponent implements OnInit {

  @Input() phoneItem : PhoneModel;
  @Output() phoneSaved = new EventEmitter<PhoneModel>();
  @Output() done = new EventEmitter<Boolean>(false);
  okIcon = 'assets/icon/ok.png';
  closeIcon = 'assets/icon/close.png';

  constructor() { }

  ngOnInit() {

  }

  saveChanges() {
    this.phoneSaved.emit(this.phoneItem);
    this.close();
  }

  toggleChange(event){
    let state = event.checked;
    this.phoneItem.active = state ? 1 : 0;
  }
  close() {
    this.done.emit(true);
  }
}
