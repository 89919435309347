import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { StorageService } from '../../services/storage/storage.service';
import { ClearAllStores } from '../../views/home/store/home.actions';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  constructor(private router: Router, private storage: StorageService, private store: Store) {}

  ngOnInit() {}

  logout() {
    localStorage.removeItem('Comunity');
    this.storage.clearAll();
    this.store.dispatch(new ClearAllStores());
    this.router.navigate(['login']);
  }
}
