import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServiceModel } from '../../../models/service.model';

@Component({
  selector: 'app-edit-service',
  templateUrl: './edit-service.component.html',
  styleUrls: ['./edit-service.component.scss']
})
export class EditServiceComponent implements OnInit {

  @Input() serviceItem : ServiceModel;
  @Output() serviceSaved = new EventEmitter<ServiceModel>();
  @Output() done = new EventEmitter<Boolean>(false);
  okIcon = 'assets/icon/ok.png';
  closeIcon = 'assets/icon/close.png';

  constructor() { }

  ngOnInit() {

  }

  saveChanges() {
    this.serviceSaved.emit(this.serviceItem);
    this.close();
  }
  toggleChange(event){
    let state = event.checked;
    this.serviceItem.active = state ? 1 : 0;
  }
  close() {
    this.done.emit(true);
  }
}
