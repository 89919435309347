import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { NzModalService } from 'ng-zorro-antd';
import { Observable } from 'rxjs';
import { OfertaModel } from '../../../models/oferta.model';
import { DeleteOferta, GetOfertas } from '../../home/store/home.actions';

@Component({
  selector: 'app-ofertas',
  templateUrl: './ofertas.component.html',
  styleUrls: ['./ofertas.component.scss']
})
export class OfertasComponent implements OnInit {
  @Select((state) => state.core.ofertas) ofertas$: Observable<any>;
  ofertas: OfertaModel[] = [];
  createMode = false;
  constructor(private store: Store, private modal: NzModalService) {}

  ngOnInit() {
    this.init();
    this.refresh();
  }

  refresh() {
    this.store.dispatch(new GetOfertas());
  }

  init() {
    this.ofertas$.subscribe((ofertas) => {
      if (ofertas) {
        this.ofertas = ofertas;
      }
    });
  }

  openNuevaOferta() {
    this.createMode = true;
  }

  onCreateClose(event) {
    this.createMode = false;
  }

  editOffer(oferta: OfertaModel) {}

  deleteOffer(oferta: OfertaModel) {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: `Va a suprimir una oferta - ${oferta.clientname} - ${oferta.title}`,
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.store.dispatch(new DeleteOferta(oferta.id));
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }
}
