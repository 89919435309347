import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-tenis',
  templateUrl: './table-tenis.component.html',
  styleUrls: ['./table-tenis.component.scss']
})
export class TableTenisComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
