import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { NzModalService } from 'ng-zorro-antd';
import { Observable } from 'rxjs';
import { ComunidadModel } from '../../model/comunidad.model';
import { HttpService } from '../../services/http/http.service';
import { StorageService } from '../../services/storage/storage.service';

@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss']
})
export class ContactSupportComponent implements OnInit {
  @Select((state) => state.core.selectedComunity) selectedComunity$: Observable<any>;

  opened = false;
  supportIcon = 'assets/img/support.png';
  closeIcon = 'assets/img/close.png';
  message;
  sent = false;
  done = false;
  listComunity: ComunidadModel[];
  selectedComunity: ComunidadModel;
  constructor(private httpService: HttpService, private modal: NzModalService) {}

  ngOnInit() {
    this.selectedComunity$.subscribe((selectedComunity) => {
      if (selectedComunity) {
        this.selectedComunity = selectedComunity;
      }
    });
  }
  open() {
    this.opened = true;
  }
  close() {
    this.opened = false;
    this.message = null;
    this.done = false;
    this.sent = false;
  }

  sendMessageToSupport() {
    if (this.selectedComunity) {
      this.httpService.sendSolicitudSoporte(this.selectedComunity.admin_name, this.selectedComunity.admin_email, this.message).subscribe(
        (response) => {
          this.done = true;
          this.sent = true;
          this.message = null;
          setTimeout(() => {
            this.close();
          }, 2000);
        },
        (error) => {
          this.done = true;
          this.sent = false;
        }
      );
    } else {
      this.modal.info({
        nzTitle: 'INFO',
        nzContent: 'Tiene que seleccionar una comunidad',
        nzOkText: 'Confirmar',
        nzOkType: 'primary',
        nzOnOk: () => {
          try {
          } catch (error) {}
        }
      });
    }
  }
}
