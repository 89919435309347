import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ModalService } from './services/modal/modal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @Select((state) => state.core.imageViewerUrl) imageViewerUrl$: Observable<any>;

  imageViewerLoaded = false;

  constructor(public modalService: ModalService) {}

  ngOnInit() {
    this.imageViewerUrl$.subscribe((imageViewerUrl) => {
      this.imageViewerLoaded = !!imageViewerUrl;
    });
  }
}
