import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../../services/modal/modal.service';
import { PhoneModel } from '../../models/phone.model';

@Component({
  selector: 'app-phone-modal',
  templateUrl: './phone-modal.component.html',
  styleUrls: ['./phone-modal.component.scss']
})
export class PhoneModalComponent implements OnInit {

  @Input() phoneItem : PhoneModel;
  @Output() phoneSaved = new EventEmitter<PhoneModel>();
  okIcon = 'assets/icon/ok.png';
  closeIcon = 'assets/icon/close.png';

  constructor(public modalService:ModalService) { }

  ngOnInit() {

  }

  saveChanges(){
    this.modalService.hidePhoneModal();
    this.phoneSaved.emit(this.phoneItem);
  }

  toggleChange(event){
    let state = event.checked;
    this.phoneItem.active = state ? 1 : 0;
  }

}
