import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { TokenV2Model } from '../../model/token-v2.model';

@Injectable()
export class AdminUserAuthInterceptor implements HttpInterceptor {
  @Select((state) => state.core.tokenV2) tokenV2$: Observable<any>;
  tokenV2: TokenV2Model;
  constructor() {
    this.tokenV2$.subscribe((tokenV2) => {
      if (tokenV2) {
        this.tokenV2 = tokenV2;
      }
    });
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.tokenV2 && request.url.includes('admin-user')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.tokenV2.accessToken}`
        }
      });
    }
    return next.handle(request);
  }
}
