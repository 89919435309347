import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss']
})
export class NoDataComponent implements OnInit {
  nodata = 'assets/img/nodata.png';
  @Input() text = 'No hay datos';
  @Input() image = true;
  constructor() {}

  ngOnInit() {}
}
